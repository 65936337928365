const SlideStatuses = Object.freeze({
  REVIEWED: 'Reviewed',
  IN_PROGRESS: 'In Progress',
  READY_FOR_REVIEW: 'Ready For Review',
  UNSORTED: 'Unsorted',
});

export const AccessionStatuses = Object.freeze({
  COMPLETE: 'Complete',
  CONSULT: 'Consult',
  IHC_STAIN: 'IHC Stain',
  IHC_LEVEL_STAIN: 'IHC/Level/Stain',
  INCOMPLETE: 'Incomplete',
  LEVEL: 'Level',
  OTHER: 'Other',
  READY_FOR_REVIEW: 'Ready For Review',
  REVIEW: 'Review',
});

export const StainRequestStatuses = Object.freeze({
  REQUESTED: 'Requested',
  REDO: 'Re-do',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
});

export const InsuredRelationships = Object.freeze({
  SELF: 'Self',
  SPOUSE: 'Spouse',
  CHILD: 'Child',
  OTHER: 'Other',
});

export const UserRoles = Object.freeze({
  SYSTEM_ADMINISTRATOR: 'System Administrator',
  LAB_ADMINISTRATOR: 'Lab Administrator',
  LAB_DIRECTOR: 'Lab Director',
  LAB_MANAGER: 'Lab Manager',
  LAB_USER: 'Lab User',
  TECHNICIAN: 'Technician',
  OFFICE_USER: 'Office User',
  PATHOLOGIST: 'Pathologist',
  TC_PATHOLOGIST: 'TC Pathologist',
  SALES_PERSON: 'Sales Person',
  PHYSICIAN: 'Physician',
  BILLING_STAFF: 'Billing Staff',
  BILLING_ADMINISTRATOR: 'Billing Administrator',
  CP_OFFICE_USER: 'CP Office User',
  CP_STAFF: 'CP Staff',
  CP_ADMINISTRATOR: 'CP Administrator',
  URO_TECHNICIAN: 'Uro Technician',
  GUEST: 'Guest',
  RESEARCHER: 'Researcher',
});

export const LoadingStatuses = Object.freeze({
  IDLE: 'idle',
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
});

export const TemplateTypes = Object.freeze({
  Diagnosis: 'Diagnosis',
  'Micro Description': 'Micro Description',
  Note: 'Note',
  Grossing: 'Grossing',
  Stain: 'Stain',
  Addendum: 'Addendum',
});

export const CancerStages = Object.freeze({
  _1a: '1A',
  _1b: '1B',
  _1c: '1C',
  _2a: '2A',
  _2b: '2B',
  _2c: '2C',
  _3a: '3A',
  _3b: '3B',
  _3c: '3C',
  _4a: '4A',
  _4b: '4B',
  _4c: '4C',
});

export const PSALevels = Object.freeze({
  low: '<10 ng/mL',
  med: '10-20 ng/mL',
  high: '>20 ng/mL',
});

export const GleasonScores = Object.freeze({
  633: '6(3+3)',
  734: '7(3+4)',
  743: '7(4+3)',
  835: '8(3+5)',
  844: '8(4+4)',
  853: '8(5+3)',
  945: '9(4+5)',
  954: '9(5+4)',
  1055: '10(5+5)',
});

export const ProstateCores = Object.freeze({
  11: '1 of 1',
  12: '1 of 2',
  22: '2 of 2',
  13: '1 of 3',
  23: '2 of 3',
  33: '3 of 3',
});

export const BILL_TYPES = {
  INSURANCE: 'bill_insurance',
  SELF: 'self_pay',
  NONE: 'no_bill',
  CASH: 'cash_pay',
  NOSTATEMENT: 'no_statement',
};

export const BillingSummaryFields = Object.freeze({
  'Accession ID': 'accession_num',
  'Date Collected': 'service_date',
  'Report Date': 'report_date',
  'Patient Name': 'patient.first_name',
  'Patient Address Line 1': 'patient.address_line_1',
  'Patient Address Line 2': 'patient.address_line_2',
  'Patient Address City': 'patient.address_city',
  'Patient Address State': 'patient.address_state',
  'Patient Address Zip': 'patient.address_zip',
  'Patient Phone': 'patient.phone',
  DOB: 'patient.dob',
  'Client name': 'practice.name',
  'Test Type': 'test_type.description',
  'Ordering Physician': 'ordering_physician',
  'Ordering Physician NPI': 'ordering_physician.npi',
  'Assignee Pathologist': 'case_assignee.name_on_report',
  ICD: 'icd_codes',
  CPT: 'cpt_codes',
  'Ordering Physician First Name': 'ordering_physician.first_name',
  'Ordering Physician Last Name': 'ordering_physician.last_name',
  'Primary Insurance Relationship': 'patient.primary_insurance.relationship',
  'Primary Insurance Member Id': 'patient.primary_insurance.member_id',
  'Primary Insurance Policy Num': 'patient.primary_insurance.policy_num',
  'Primary Insurance Group Num': 'patient.primary_insurance.group_num',
  'Primary Insurance Insured Last Name': 'patient.primary_insurance.insured_last_name',
  'Primary Insurance Insured First Name': 'patient.primary_insurance.insured_first_name',
  'Primary Insurance Insured Dob': 'patient.primary_insurance.insured_dob',
  'Primary Insurance Name': 'patient.primary_insurance.insurance.name',
  'Primary Insurance Friendly Name': 'patient.primary_insurance.insurance.friendly_name',
  'Primary Insurance Phone': 'patient.primary_insurance.insurance.phone',
  'Primary Insurance Is Active': 'patient.primary_insurance.insurance.is_active',
  'Secondary Insurance Relationship': 'patient.secondary_insurance.relationship',
  'Secondary Insurance Member Id': 'patient.secondary_insurance.member_id',
  'Secondary Insurance Policy Num': 'patient.secondary_insurance.policy_num',
  'Secondary Insurance Group Num': 'patient.secondary_insurance.group_num',
  'Secondary Insurance Insured Last Name': 'patient.secondary_insurance.insured_last_name',
  'Secondary Insurance Insured First Name': 'patient.secondary_insurance.insured_first_name',
  'Secondary Insurance Insured Dob': 'patient.secondary_insurance.insured_dob',
  'Secondary Insurance Name': 'patient.secondary_insurance.insurance.name',
  'Secondary Insurance Friendly Name': 'patient.secondary_insurance.insurance.friendly_name',
  'Secondary Insurance Phone': 'patient.secondary_insurance.insurance.phone',
  'Secondary Insurance Is Active': 'patient.secondary_insurance.insurance.is_active',
  'Tertiary Insurance Relationship': 'patient.tertiary_insurance.relationship',
  'Tertiary Insurance Member Id': 'patient.tertiary_insurance.member_id',
  'Tertiary Insurance Policy Num': 'patient.tertiary_insurance.policy_num',
  'Tertiary Insurance Group Num': 'patient.tertiary_insurance.group_num',
  'Tertiary Insurance Insured Last Name': 'patient.tertiary_insurance.insured_last_name',
  'Tertiary Insurance Insured First Name': 'patient.tertiary_insurance.insured_first_name',
  'Tertiary Insurance Insured Dob': 'patient.tertiary_insurance.insured_dob',
  'Tertiary Insurance Name': 'patient.tertiary_insurance.insurance.name',
  'Tertiary Insurance Friendly Name': 'patient.tertiary_insurance.insurance.friendly_name',
  'Tertiary Insurance Phone': 'patient.tertiary_insurance.insurance.phone',
  'Tertiary Insurance Is Active': 'patient.tertiary_insurance.insurance.is_active',
});

export default Object.freeze({
  SlideStatuses,
  StainRequestStatuses,
  UserRoles,
  AccessionStatuses,
  LoadingStatuses,
});

export const SettingsTabs = Object.freeze({
  GENERAL: 'general',
  USERS: 'users',
  FIELD_MANAGEMENT: 'field_management',
  PANEL_LIST: 'panel_list',
  INSTRUMENTAL_LIST: 'instrumental_list',
  REFERENCE_RANGE: 'reference_range',
  TEST_LIST: 'test_list',
});

export const adminTabs = [
  { value: SettingsTabs.GENERAL, label: 'General' },
  { value: SettingsTabs.USERS, label: 'Users' },
  { value: SettingsTabs.FIELD_MANAGEMENT, label: 'Field Management' },
];
export const adminCPTabs = [
  { value: SettingsTabs.PANEL_LIST, label: 'Panel list' },
  { value: SettingsTabs.INSTRUMENTAL_LIST, label: 'Instrument list' },
  { value: SettingsTabs.REFERENCE_RANGE, label: 'Reference range' },
  { value: SettingsTabs.TEST_LIST, label: 'Test List' },
];

export const statusColorMap = {
  [AccessionStatuses.COMPLETE]: {
    bg: 'rgba(102, 187, 106, 0.08)',
    color: 'rgb(102, 187, 106)',
  },
  [AccessionStatuses.CONSULT]: {
    bg: 'rgba(144, 202, 249, 0.08)',
    color: 'rgb(144, 202, 249)',
  },
  [AccessionStatuses.IHC_STAIN]: {
    bg: 'rgba(255, 255, 70, 0.08)',
    color: 'rgb(255, 255, 70)',
  },
  [AccessionStatuses.IHC_LEVEL_STAIN]: {
    bg: 'rgba(255, 255, 70, 0.08)',
    color: 'rgb(255, 255, 70)',
  },
  [AccessionStatuses.LEVEL]: {
    bg: 'rgba(255, 255, 70, 0.08)',
    color: 'rgb(255, 255, 70)',
  },
  // [AccessionStatuses.OTHER]: {
  //   bg: 'rgba(255, 255, 255, 0.08)',
  //   color: 'rgb(255, 255, 255)',
  // },
  // [AccessionStatuses.INCOMPLETE]: {
  //   bg: 'rgba(255, 255, 255, 0.08)',
  //   color: 'rgb(255, 255, 255)',
  // },
  // [AccessionStatuses.READY_FOR_REVIEW]: {
  //   bg: 'rgba(255, 255, 255, 0.08)',
  //   color: 'rgb(255, 255, 255)',
  // },
  // [AccessionStatuses.REVIEW]: {
  //   bg: 'rgba(255, 255, 255, 0.08)',
  //   color: 'rgb(255, 255, 255)',
  // },
};
